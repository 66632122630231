.notification-container-ok {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 15px;
  position: fixed;
  bottom: 40px;
  z-index: 20;
  background: white;
  border: 2px solid @COLOR-HIGHLIGHT;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 2px 0, rgba(0, 0, 0, 0.3) 0 2px 6px 0;
}

.notification-msg-ok {
  font-family: @FONT-AVENIR-MEDIUM;
  font-size: 16px;
  color: @COLOR-HIGHLIGHT;
  margin-left: 12px;
}

.notification-container-error {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 15px;
  position: fixed;
  bottom: 40px;
  z-index: 20;
  background: white;
  border: 2px solid red;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 2px 0, rgba(0, 0, 0, 0.3) 0 2px 6px 0;
}

.notification-msg-error {
  font-family: @FONT-AVENIR-MEDIUM;
  font-size: 16px;
  color: red;
  margin-left: 12px;
}

.notOkIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.closeIconBlack {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 4px;
  right: 4px;
}

@media only screen and (min-width: 768px) {
  .notification-container {
    top: 40px;
    width: 350px;
    bottom: unset;
  }
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;