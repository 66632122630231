.input-image-wrapper {}
.invisible {
  z-index: 2;
  opacity: 0;
}

.layout {
  position: relative;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 13px;
}

.layout.invisible {
  z-index: 2;
  opacity: 0;
}

.layout.transparent {
  background-color: transparent;
}

.addImage {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.addImage.full {
  display: unset;
  width: 50%;
}

.input-image {
  padding: 24px;
  height: inherit;
  border-radius: 5px;
}

.trashTouchable {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: @COLOR-WHITE-07;
  border-radius: 4px;
}

.trash {
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 768px) {
  .addImage.full {
    display: unset;
    width: 20%;
  }
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;