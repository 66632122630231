.conditions-wrapper {
  display: flex;
  background: white;
  text-align: center;
  margin: 24px 0 4px;
}
.condition-item-offline {
  box-sizing: border-box;
  width: 33.1%;
  border: 1px solid #f1f1f1;
  margin: 0 5px;
}
.condition-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 50%;
  border: 1px solid #f1f1f1;
  margin: 5px 5px;
  padding: 15px 10px;
}
.condition-item .condition-value {
  font-size: 17px;
  margin: 0;
}
.condition-left-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.condition-left-item .condition-label {
  margin: 0 10px;
}
.invalid.condition-item {
  background: #ed4e4d;
}
.invalid.condition-item-offline {
  background: #ed4e4d;
}
.condition-value {
  font-family: Avenir Next, serif;
  color: #3f8e21;
  font-size: 18px;
  font-weight: 600;
  margin: 12px 0;
}
.condition-label {
  font-family: Avenir Next, serif;
  font-size: 15px;
  margin-top: 7px;
  font-weight: 600;
  color: black;
}
.invalid .condition-value,
.invalid .condition-label {
  color: #fff;
}
.no-data .condition-value,
.no-data .condition-label {
  color: #979797;
}

.condition-image {
  height: 20px;
}

.button-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.refresh-button {
  margin: 0;
  text-align: left;
  width: fit-content;
  padding-right: 15px;
}

.refresh-button-txt {
  margin-left: 10px;
}

.refresh-button-last-modified {
  color: grey;
  margin-left: 15px;
  margin-right: 2px;
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;