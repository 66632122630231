.brand-btn {
  position: absolute;
  bottom: 20px;
  left: 15px;
  right: 15px;
  width: calc(100% - 30px);
  z-index: 3;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  background-color: @COLOR-BUTTON;
  border: 3px solid #FFFFFF;
  color: @COLOR-BUTTON-TEXT;
  font-family: @FONT-BOLD;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.51);
  box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.51);
  cursor: pointer;
}

.disabled-brand-btn {
  position: absolute;
  bottom: 20px;
  left: 15px;
  right: 15px;
  width: calc(100% - 30px);
  z-index: 3;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  background-color: #F5F5F5;
  border: 3px solid #FFFFFF;
  color: rgba(0, 0, 0, 0.25);
  font-family: @FONT-BOLD;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.51);
  box-shadow: 0px 0px 21px -5px rgba(0,0,0,0.51);
}

.brand-btn:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;