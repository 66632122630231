.nav-bar-wrapper {
  background-color: @COLOR-HEADER;
  padding-bottom: 20px;
  min-height: 52px;
}

.nav-bar-header-txt-container {
  display: flex;
  flex-direction: column;
}

.nav-bar-title {
  color: @COLOR-CORPORATE-C-LIGHT;
  padding: 46px 15px 4px 15px;
  font-size: 26px;
  font-family: @FONT-BOLD;
  margin-bottom: 0;
}

.nav-bar-description {
  padding: 0 15px 0 15px;
  color: @COLOR-CORPORATE-C-LIGHT;
  font-size: 16px;
  font-family: @FONT-AVENIR-REGULAR;
  margin-bottom: 0;
}

.nav-bar-left-wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 4px;
  top: 4px;
  z-index: 1;
  cursor: pointer;
}

.nav-bar-close {
  margin: 12px;
}
.nav-bar-back {
  margin: 12px;
}

.nav-bar-top-right-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
}
.nav-bar-chat {
  padding: 12px;
  cursor: pointer;
}
.nav-bar-menu {
  padding: 12px;
  cursor: pointer;
}
.nav-bar-app-version {
  padding: 12px 8px 10px 12px;
  color: @COLOR-CORPORATE-C-LIGHT;
  font-size: 11px;
  font-weight: bolder;
  font-family: @FONT-AVENIR-REGULAR;
}
.nav-bar-logout-txt {
  color: black;
  font-size: 14px;
  font-weight: bold;
  font-family: @FONT-AVENIR-REGULAR;
}

@media only screen and (min-width: 768px) {
  .nav-bar-wrapper {
    width: 60%;
  }
  .nav-bar-header-txt-container {
    align-items: center;
  }
  .nav-bar-app-version {
    padding: 12px 30px 10px 12px;
    font-size: 12px;
  }
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;