.buttons-wrapper {
  display: flex;
}
.lock.button {
  width: 100%;
  margin: 8px 0;
  color: black;
  border-radius: 8px 0 0 8px;
  border: 2px solid black;
  border-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.lock.button:disabled {
  width: 100%;
  margin: 8px 0;
  color: black;
  border-radius: 8px 0 0 8px;
  border: 2px solid black;
  border-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.unlock.button {
  width: 100%;
  margin: 8px 0;
  color: black;
  border-radius: 0 8px 8px 0;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.unlock.button:disabled {
  width: 100%;
  margin: 8px 0;
  color: black;
  border-radius: 0 8px 8px 0;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.icon {
  display: inline-block;
  margin-right: 8px;
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;