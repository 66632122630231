.MuiDialogTitle-root {
  font-family: @FONT-BOLD !important;
  font-size: 17px !important;
}

.MuiDialogContentText-root {
  font-family: @FONT-AVENIR-MEDIUM !important;
  font-size: 15px !important;
}

.MuiButton-root {
  font-family: @FONT-AVENIR-MEDIUM !important;
  font-size: 14px !important;
}

.dialog-list-action {
  font-family: @FONT-AVENIR-MEDIUM;
  font-size: 15px;
  color: #0077BE;
  padding: 5px;
  cursor: pointer;
}

.dialog-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;